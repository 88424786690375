<template>
  <!-- ellipsis has issues when the primary div here is set to anything other than d-inline -SNG -->

  <div v-if="!disableEditingFlag"
       ref="popoverTarget"
       class="d-inline-block"
       :class="{ 'table-action-container mx-1': useTableActionContainer}" @click.stop>
    <a class="popover-trigger table-action cursor-pointer"
       :class="[linkClasses]"
       v-p-tooltip.top="{ value: tooltip, disabled: !tooltip }">
      <slot name="trigger-content">
        <fluency-icon :size="iconSize" type="edit"/>
      </slot>
    </a>
    <tasty-popover v-bind="$attrs"
                   v-if="popoverTarget"
                   :target="popoverTarget"
                   :show="localShow"
                   :triggers="props.triggers"
                   @show="showDialog"
                   @hide="hideDialog">
      <div :style="'width:' + width"
           @click.stop
           @keyup.esc.stop="cancel()"
           @keyup.enter.stop="!disableEnterKeySave && save()">
        <div class="d-flex-center pt-3 pb-5">
          <h5 v-if="props.title" class="mb-0">{{props.title}}</h5>
          <t-button-inline severity="secondary" class="ml-auto" @click.stop="cancel()">
            <fluency-icon type="close"></fluency-icon>
          </t-button-inline>
        </div>
        <fluency-loader v-if="isLoading" dots />
        <slot name="edit"
              :value="valueStore"
              :valueRef="getValueStoreRef()"
              :validate="isValid => isValueValid = isValid"
              :loading="(isLoad) => isLoading = isLoad"
              :update="editSlotUpdateHandler"
              :applyEnterKeypressBubble="editSlotSaveHandler">
        </slot>
        <div class="m-0 pt-3 d-flex justify-content-end">
          <b-btn v-if="showSaveBtn" :disabled="!isValueValid" type='submit' variant="primary" @click.stop.prevent="save()">{{okText}}</b-btn>
        </div>
      </div>

    </tasty-popover>
  </div>
</template>

<script setup>
import { ref, watch, computed, nextTick, watchEffect } from 'vue'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import _delay from 'lodash.delay'

defineOptions({
  inheritAttrs: false
})
const props = defineProps({
  value: {
    type: [String, Object, Number, Boolean, Array]
  },
  cancelText: {
    type: String,
    default: 'Cancel'
  },
  okText: {
    type: String,
    default: 'Apply'
  },
  width: {
    type: String,
    default: '250px'
  },
  show: {
    type: Boolean,
    default: false
  },
  disableEditing: { // setting to switch off
    type: Boolean,
    default: false
  },
  showSaveBtn: {
    type: Boolean,
    default: true
  },
  triggers: {
    type: String,
    default: 'click clickOff'
  },
  tooltip: {
    type: String
  },
  linkClasses: {
    type: String,
    default: ''
  },
  iconSize: {
    type: String,
    default: 'sm'
  },
  // boundary: {
  //   type: String,
  //   default: 'scrollParent'
  // },
  useTableActionContainer: {
    type: Boolean,
    default: true
  },
  disableEnterKeySave: {
    type: Boolean,
    default: false
  },
  title: {
    type: String
  }
})
const $emit = defineEmits(['update:show', 'cancel', 'input', 'save'])
const nuxtApp = useNuxtApp()
// const attrs = useAttrs()

const valueStore = ref('')
const getValueStoreRef = () => valueStore.value

/* This is all the stuff to render the b-popover and then teleport content to it */
// const teleport = ref(false)
const localShow = ref(props.show)
watchEffect(() => {
  localShow.value = props.show
})

// const popoverShow = = ref(props.show)
const popoverTarget = ref(null) // actual ref in template

const isEditing = ref(false)
const isValueValid = ref(true)
const isLoading = ref(false)

const syncValueStore = () => {
  if (Array.isArray(props.value)) {
    valueStore.value = [...props.value]
  } else if (typeof props.value === 'object') {
    valueStore.value = { ...props.value }
  } else {
    valueStore.value = props.value
  }
}
watch(() => props.value, () => syncValueStore(), { immediate: true })
// watch(() => props.show, (val, oldVal) => {
//   if (val !== oldVal) {
//     if (val && !isEditing.value) { // show
//       showDialog()
//       // popoverShow.value = true
//     } else if (!val && isEditing.value) { // hide
//       // popoverShow.value = false
//       cancel()
//     }
//   }
// }, { immediate: true })
const editSlotSaveHandler = () => {
  _delay(() => {
    save()
  }, 500) // give the blur bindings time to trigger
}
const editSlotUpdateHandler = (val) => {
  valueStore.value = val
}
const disableEditingFlag = computed(() => {
  return props.disableEditing || readOnly.value
})
const readOnly = computed(() => {
  return (user.value.roles || []).includes('read_only')
})
const user = computed(() => {
  return nuxtApp.$store.getters.user || { email: '', roles: [] }
})
const showDialog = (evt) => {
  if (disableEditingFlag.value) return

  syncValueStore()
  // popoverShow.value = true
  isEditing.value = true
  localShow.value = true
  $emit('update:show', true)
}
const hideDialog = (isCancel = false) => {
  isEditing.value = false
  isValueValid.value = true
  if (isCancel) {
    $emit('cancel', valueStore.value)
  }
  localShow.value = false
  $emit('update:show', false)
}
const cancel = () => {
  hideDialog(true)
}
const save = () => {
  isEditing.value = false
  $emit('input', valueStore.value)
  nextTick(() => $emit('save', valueStore.value))
  localShow.value = false
  $emit('update:show', false)
}

const focusPopover = () => {
  // put focus on the popover so that a click off will trigger the blur event
  // checking to see if focus is already going in so this doesn't take it off of an input with autofocus set or anything like that
  const el = document.querySelector('.b-popover')
  const activeElement = document.activeElement
  if (el && el !== activeElement && !el.contains(activeElement)) {
    el.focus()
  }
  if (props.triggers.includes('clickOff')) {
    const clickOff = (event) => {
      if (!el.contains(event.target) && event.target?.localName !== 'body') {
        // Checking to make sure `body` tag isn't selected for an instance when clicking out of a MadLib into certain
        // areas of the `b-popover` here returns the body tag.
        cancel()
        document.removeEventListener('mousedown', clickOff)
      }
    }
    document.addEventListener('mousedown', clickOff)
  }
}
watch(
  () => localShow.value,
  (value) => {
    if (value) {
      focusPopover()
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
  .focus-ring:focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  }
</style>
